<template>
    <div class="card-item">
        <div class="def-item">
            <div class="def-img">
                <img :src="needObj.defImg" alt="">
            </div>
                
            <h3 class="def-title">{{needObj.title}}</h3>

            <div class="def-text">
                {{needObj.text }}
            </div>
        </div>

        <component 
        class="hover-item"
        :class="{hoverable: needObj.href}"
        :is="needObj.href ? 'a' : 'div'"
        :href="needObj.href"
        target="_blank"
        >
            <div class="hover-img">
                <img :src="needObj.hoverImg" alt="">

                <i
                    v-if="needObj.href"
                    class="icon-open el-icon-right"
                ></i>
            </div>

            <h3>{{needObj.title}}</h3>

            <div class="hover-text">
                <div v-for="(item,index) in needObj.hoverTextList" :key="index">
                    {{item}}
                </div>
            </div>
        </component>
    </div>
</template>

<script>

export default {
    props:{
        needObj:{
            type:Object,
            default:function(){
                return {}
            }
        }
    },
    data() {
        return {

        };
    },
    mounted() {},
    methods: {

    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
$height: 370px;
    .card-item{
        height: $height;
        border: solid 1px #d3d3d3;
        position: relative;

        .def-item{
            height: $height;
            width: 100%;
            position: absolute;
            top:0;
            left: 0;
            padding: 0 18px;
            transition: all 0.7s;

            .def-img {
                height: 70px;
                margin: 24px 0 0 0;
                img{
                    // height: 50px;
                }
            }
             

            h3{
                font-size: 21px;
                padding-top: 20px;
                padding-bottom: 25px;
            }
            .def-text{
                line-height: 1.6em;
            }
        }

        .hover-item{
            height: $height;
            width: 100%;
            position: absolute;
            top:0;
            left: 0;
            background-color: #546aff;
            opacity: 0;
            transform:rotateY(90deg);
            transition: all 0.7s;
            padding: 0 18px;

            &.hoverable {
                cursor: pointer;
            }

            .hover-img{
                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                position: relative;
                height: 70px;
                margin: 24px 0 0 0;

                img{
 
                }

                .icon-open{
                    position: absolute;
                    top: 14px;
                    right: 0;
                    font-size: 30px;
                    cursor: pointer;
                    color: white;
                    box-sizing: border-box;

                    &:hover{
                        // border: 2px solid white;
                        // border-radius: 50%;
                    }
                }
            }

            h3{
                font-size: 21px;
                padding-top: 20px;
                padding-bottom: 25px;
            }

            .hover-text{
                div{
                    display: inline-block;
                    border: 1px solid white;
                    padding: 4px 8px;
                    margin-right: 6px;
                    margin-bottom: 6px;
                }
            }
        }

        &:hover{

            .def-item{
                opacity: 0;
                // transform: rotateY(90deg); 
            }
            .hover-item{
                color: white;
                opacity: 1;
                transform: rotateY(0deg); 
            }

        }
    }
</style>
