<template>
    <div class="container">
        <div class="content">
            <sub-title
                v-if="subTitleShow"
                title="解决方案"
                class="title"
            ></sub-title>

            <div class="all-sole-card">
                <solution-card 
                    v-for="(item,index) in solutionCardList" 
                    :key="index" 
                    :need-obj="item"
                >
                </solution-card>
            </div>
        </div>
    </div>
</template>

<script>
import SubTitle from './sub-title';
import solutionCard from './solution-card.vue';
export default {
    components: { 
        SubTitle,
        solutionCard 
    },
    props:{
        subTitleShow:{
            type:Boolean,
            default:true,
        }
    },
    data() {
        return {
            solutionCardList:[
                {
                    defImg:require('@/static/images/solution/def1.png'),
                    title:'招标采购电子交易解决方案',
                    text:'严格依照《招标投标法》、《政府采购法》等相关法规设计形式、内容和标准，依法合规实现招、投、开、评、定全流程的电子化交易。电子招标投标交易平台（惠招标）是国家发改委2015年电子招投标交易系统的首批试点单位，为各方主体提供国内领先的招采数智化解决方案。',
                    hoverImg:require('@/static/images/solution/hover1.png'),
                    hoverTextList:[
                        '计划需求管理','招投标管理','采购项目管理','代理机构管理','专家库管理','文档模板管理','监督管理','供应商管理','全程留痕','自动归档','智能分析'
                    ],
                    href: '/products/details/zhao-biao-cai-gou',
                },
                {
                    defImg:require('@/static/images/solution/def2.png'),
                    title:'企业招标采购一体化解决方案',
                    text:'从采购全过程出发，以“内控+采购”的形式搭建平台，不仅完美实现内部的预算管理、计划申报、计划审批、合同管理、档案管理，开辟监督审计通道，同时对接采购交易平台来实施全流程线上招标采购活动，为企业打造绝佳的招标采购体验。',
                    hoverImg:require('@/static/images/solution/hover2.png'),
                    hoverTextList:[
                        '灵活组合','预算管理','计划审批','采购管理','数据管理','合同验收','智能查阅','异地远程评标','对接已有平台','辅助采购决策 ',
                    ],
                    href: '/products/details/qi-ye-zhao-cai',
                },
                {
                    defImg:require('@/static/images/solution/def3.png'),
                    title:'土地产权交易数字化解决方案',
                    text:'严格遵循城乡统一建设用地市场要求，综合考虑国土部门信息化现状和登记成果数据现状，利用可用资源，构建信息网络，完成交易数据建库，采用“互联网+土地交易”的理念实施建设，实现土地的交易与登记有序衔接，确保交易资金安全、市场有序，避免风险。',
                    hoverImg:require('@/static/images/solution/hover3.png'),
                    hoverTextList:[
                        '土地交易','土地招标','土地拍卖','土地挂牌','足不出户','公平公正',
                    ],
                },
                {
                    defImg:require('@/static/images/solution/def4.png'),
                    title:'农村产权交易数字化解决方案',
                    text:'针对农村产权交易的特点，按照高标准质量的工作要求和原则精心搭建平台，应用大数据、区块链、人工智能、物联网、地理信息等技术，可实现省、市、县、乡、村五级业务管理体系，涵盖了从农村资产管理、农村产权交易、农村招标采购等多维一体的全生态服务，为农村产权交易保驾护航。',
                    hoverImg:require('@/static/images/solution/hover4.png'),
                    hoverTextList:[
                        '农村产权交易','农村产权管理','土地流转管理','多级管理体系',
                    ],
                },
                {
                    defImg:require('@/static/images/solution/def5.png'),
                    title:'国有资产交易数字化解决方案',
                    text:'对各类国有资产交易流程进行整合，采取线上竞价方式，参与者不用到场，在任意能连通互联网的地方登陆竞价系统即可参与竞价，避免传统现场竞拍方式中的串标、围标、控场等现象，既安全又高效。同时能够满足上万人同时竞价，实现了规模化交易，大大降低交易成本。',
                    hoverImg:require('@/static/images/solution/hover5.png'),
                    hoverTextList:[
                        '线上交易','安全无忧','降本增效','多种交易形式',
                    ],
                },
                {
                    defImg:require('@/static/images/solution/def6.png'),
                    title:'行政服务平台数智化解决方案',
                    text:'可为各级公共服务以及行政监督部门搭建平台，在交易、服务、硬件管理、见证和监督、数据共享、档案管理方面实现智慧管理，从而为市场主体提供了更加安全便捷的交易环境，助推行政服务数字化转型升级。',
                    hoverImg:require('@/static/images/solution/hover6.png'),
                    hoverTextList:[
                        '公共服务平台','行政监督平台','数据共享','档案管理',
                    ],
                    href: '/products/details/xing-zheng-fu-wu',
                },
                {
                    defImg:require('@/static/images/solution/def7.png'),
                    title:'工程咨询企业信息化解决方案',
                    text:'“惠咨询”是针对工程咨询企业特点的信息化解决方案，具有灵活的体系架构，以项目为主线贯穿整个业务流程。既实现业务全生命周期各阶段管理，又实现任务分配、进度控制、质量审核、绩效管理以及专项数据分析，搭配自动化办公系统，形成了集项目管理、财务管理、行政管理和知识管理为一体的工程咨询企业综合性管理平台。',
                    hoverImg:require('@/static/images/solution/hover7.png'),
                    hoverTextList:[
                        '任务分配','进度控制','质量审核','绩效管理','项目管理','财务管理','行政管理','知识管理',
                    ],
                    href: 'http://www.hgchzx.com',
                },
                {
                    defImg:require('@/static/images/solution/def8.png'),
                    title:'全过程工程项目管理信息化解决方案',
                    text:'全过程工程项目管理协同平台是项目和项目群集成化协同平台，围绕工程建设全生命周期，以业主为核心，各参建方协同，实现工程项目从建设手续、投资管理、进度管理、质安管理、项目监控、档案管理等实现多维度管控。项目建设信息全程记录、永久追溯，可为业主决策提供动态数据支持。',
                    hoverImg:require('@/static/images/solution/hover8.png'),
                    hoverTextList:[
                        '项目管理','投资管理','进度管理','质安管理','档案管理','永久追溯','数据分析','科学决策',
                    ],
                    href: '/products/details/gong-cheng-xiang-mu',
                },
                {
                    defImg:require('@/static/images/solution/def9.png'),
                    title:'企业教培考核数字化解决方案',
                    text:'为政企单位量身定制的的线上培训及考核一体化专业解决方案，可以全方位满足业务、技能培训，降低培训成本，提高培训效率。',
                    hoverImg:require('@/static/images/solution/hover9.png'),
                    hoverTextList:[
                        '线上报名','自主学习','多人多场考试','防舞弊','智能分析','自动开具合格证书',
                    ],
                    href: '/products/details/qi-ye-jiao-pei'
                },
                {
                    defImg:require('@/static/images/solution/def10.png'),
                    title:'高效精准投标智能化解决方案',
                    text:'投标E站是向招投标行业，致力于大数据赋能、智能建模、效率办公的工具型产品。为投标人或供应商在投标前找商机找项目找人脉，通过企业画像获悉甲方及竞争对手多维度信息，在投标中快速制作投标方案。在投标后依靠历年招投标行业数据快速了解市场及行业动态，为企业发展做好预判。',
                    hoverImg:require('@/static/images/solution/hover10.png'),
                    hoverTextList:[
                        '投标人专区','商机获取','项目评估','竞争对手分析','制作投标方案','高效办公','企业画像',
                    ],
                    // href: 'http://www.huitoue.com',
                },
                {
                    defImg:require('@/static/images/solution/def11.png'),
                    title:'专业招标知识共享平台化解决方案',
                    text:'招标E站是一个专业招标知识的 “共享平台”，通过签约专家发布的招投标动态及专业文章分析、招标知识视频及课程等形式获悉行业动态及知识。专业的问答模块，让问题快速得到专家的答复，解决招标过程中的疑难问题；大而全且精的文件模板，实现上传下载，提高工作效率，是招标人的专业知识共享聚集地。',
                    hoverImg:require('@/static/images/solution/hover11.png'),
                    hoverTextList:[
                        '招标人专区','招标文件模板下载','专家聚集','定向答疑','招标知识课程','行业动态分析',
                    ],
                    // href: 'http://27.128.172.214:12014/'
                },
                {
                    defImg:require('@/static/images/solution/def12.png'),
                    title:'工程咨询数字综合云端服务解决方案',
                    text:'云咨空间是工程咨询行业资源众包平台，覆盖工程咨询21个行业，涵盖规划咨询、项目咨询、专项咨询等服务类型，提供发布需求、报告编制及评估评审协作、在线问答、专家智库、资讯阅读、资料查询等全方位服务，为各类工程咨询机构、公司、工程师、专家等营造一个资源共享的项目协作环境，实现解决行业从业人员资源共享、行业专家资源共享和工程咨询公司人力成本问题。',
                    hoverImg:require('@/static/images/solution/hover12.png'),
                    hoverTextList:[
                        '报告编制协作','评估评审协作','专家智库','知识库管理','实名认证','项目极速对接','安全高效','质量保障','回款保障','行业信息归集','信息数智化技术','降本增效',
                    ],
                    // href: 'https://www.yunzikongjian.com/',
                },
            ],
        };
    },
    mounted() {},
    methods: {


    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
.container {
    background: #fff;
    padding-bottom:40px;
    .content {

        .title {
            // padding-top: 70px;
            padding-top: 70px;
            padding-bottom: 70px;
        }

        .all-sole-card{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .card-item{
                width: calc(33.33% - 10px);
                margin-bottom: 16px ;
            }


        }

    }
}
</style>
