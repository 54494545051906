<template>
    <div class="title">
        <img :src="iconImg" />
        {{ title }}
        <img :src="iconcImg" />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '标题',
        },
    },
    data() {
        return {
            iconImg: require('@/static/images/home/icon.png'),
            iconcImg: require('@/static/images/home/iconc.png'),
        };
    },
    mounted() {},
    methods: {},
};
</script>
<style lang='scss' scoped>
.title {
    font-size: 36px;
    color: #333333;
    text-align: center;
    img {
        vertical-align: middle;
        margin-top: -2px;
    }
}
</style>
